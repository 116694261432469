(function($) {

    $.fitVid = function(element, options) {

    	var defaults = {
            parent: {
                element: $(element).parent(),
                width: 0,
                height: 0
            },
            ratio: null,
            width: 0,
            height: 0,
            top:0,
            left:0,
            mode: 'cover',
            position: 'center',
        }

        var plugin = this;

        plugin.settings = {};

        var $element = $(element),
            element = element;

        plugin.init = function() {

            plugin.settings = $.extend({}, defaults, options);

            var referenceSize;

            $(window).resize(function() {
                getVideoSize();
                computeVideoSize();
                setVideoSize();
            });

            if(plugin.settings.ratio === null){
                getVideoRatio();
            }
            getVideoSize();
            computeVideoSize();
            setVideoSize();

        }

        plugin.foo_public_method = function() {
            // code goes here
        }

        var getVideoSize = function(){
            plugin.settings.parent.width = plugin.settings.parent.element.width();
            plugin.settings.parent.height = plugin.settings.parent.element.height();
        };

        var getVideoRatio = function(){
            plugin.settings.ratio = $element.width() / $element.height();
        };

        var computeVideoSize = function(){

            if(plugin.settings.mode === 'cover'){

                if( (plugin.settings.parent.width / plugin.settings.parent.height) < plugin.settings.ratio){
                    //console.log('full height', plugin.settings);
                    plugin.settings.height = plugin.settings.parent.height;
                    plugin.settings.width = Math.ceil(plugin.settings.parent.height * plugin.settings.ratio);
                    plugin.settings.left = Math.ceil((plugin.settings.parent.width - plugin.settings.width)/2);
                    plugin.settings.top = 0;
                }else{
                    //console.log('full width');
                    plugin.settings.width = plugin.settings.parent.width;
                    plugin.settings.height = Math.ceil(plugin.settings.width / plugin.settings.ratio);
                    plugin.settings.left = 0;
                    plugin.settings.top = Math.ceil((plugin.settings.parent.height - plugin.settings.height)/2);
                }

            }

            if(plugin.settings.mode === 'fit'){

                // Wrong settings
                if( (plugin.settings.parent.width / plugin.settings.parent.height) < plugin.settings.ratio){
                    //console.log('full height', plugin.settings);
                    plugin.settings.height = plugin.settings.parent.height;
                    plugin.settings.width = plugin.settings.parent.height * plugin.settings.ratio;
                    plugin.settings.left = (plugin.settings.parent.width - plugin.settings.width)/2;
                    plugin.settings.top = 0;
                }else{
                    //console.log('full width');
                    plugin.settings.width = plugin.settings.parent.width;
                    plugin.settings.height = plugin.settings.width / plugin.settings.ratio;
                    plugin.settings.left = 0;
                    plugin.settings.top = (plugin.settings.parent.height - plugin.settings.height)/2;
                }

            }

        };

        var setVideoSize = function(){

            /*
            $element.css({
                width:plugin.settings.width,
                height:plugin.settings.height,
                top:plugin.settings.top,
                left:plugin.settings.left
            });
            */

            TweenMax.set($element, {width:plugin.settings.width, height:plugin.settings.height, y:plugin.settings.top, x:plugin.settings.left});

        };

        plugin.init();

    }

    $.fn.fitVid = function(options) {

        return this.each(function() {
            if (undefined == $(this).data('fitVid')) {
                var plugin = new $.fitVid(this, options);
                $(this).data('fitVid', plugin);
            }
        });

    }

})(jQuery);
