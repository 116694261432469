
class videoPlayerControls {

    constructor(element, controls) {

        this.video = element;
        this.controls = controls;
        this.callback = null;

        // Ui
        this.playpause = this.controls.querySelector("button.playpause");
        this.mute = this.controls.querySelector("button.mute");
        this.progress = this.controls.querySelector("div.progress");
        this.progressBar = this.controls.querySelector('div.progress-bar');

        // Buffering
        this.checkInterval  = 50.0; // check every 50 ms (do not use lower values)
        this.lastPlayPos    = 0;
        this.currentPlayPos = 0;
        this.bufferingDetected = false;
        this.afterLoopCounter = 0;

        this.registerEvents();
        setInterval(this.checkBuffering.bind(this), this.checkInterval);

    }

    registerEvents(){

        this.mute.addEventListener('click', e => {
            this.video.muted = !this.video.muted;
            this.changeButtonState('mute');
        });

        this.playpause.addEventListener('click', e => {
            if (this.video.paused || this.video.ended) this.video.play();
            else this.video.pause();
            this.changeButtonState('playpause');
        });

        this.progress.addEventListener('click', e => {
            var pos = (e.pageX  - (this.progress.offsetLeft + this.progress.offsetParent.offsetLeft)) / this.progress.offsetWidth;
            this.video.currentTime = pos * this.video.duration;
        });

    }

    registerCallback(callback){
        this.callback = callback;
    }

    changeButtonState(type) {

        let state;

        // Play/Pause button
        if (type == 'playpause') {
            if (this.video.paused || this.video.ended) {
                this.playpause.setAttribute('data-state', 'play');
                state = 'pause';
            }
            else {
                this.playpause.setAttribute('data-state', 'pause');
                state = 'play';
            }
        }else if (type == 'mute') {
            this.mute.setAttribute('data-state', this.video.muted ? 'unmute' : 'mute');
            state = this.video.muted ? 'mute' : 'unmute';
        }

        if(this.callback !== null){
            this.callback(type, state);
        }

    }

    checkBuffering(){

        if(this.afterLoopCounter > 0){
            this.afterLoopCounter--;
            return;
        }

        this.currentPlayPos = this.video.currentTime;

        // checking offset should be at most the check interval
        // but allow for some margin
        var offset = (this.checkInterval - 20) / 1000

        // if no buffering is currently detected,
        // and the position does not seem to increase
        // and the player isn't manually paused...


        // Exception if its looping / restarting
        if(Math.abs(this.currentPlayPos - (this.lastPlayPos + offset)) > 4.5){
            //console.log('Video has Looped');
            this.lastPlayPos = this.currentPlayPos;
            this.afterLoopCounter = 5;
            return;
        }

        //console.log(currentPlayPos, (lastPlayPos + offset), currentPlayPos < (lastPlayPos + offset));

        if (
            !this.bufferingDetected
            && this.currentPlayPos < (this.lastPlayPos + offset)
            && !this.video.paused
        ) {
            //console.log("buffering")
            if(this.callback !== null){
                this.callback('video', 'buffering');
            }
            this.bufferingDetected = true
        }

        // if we were buffering but the player has advanced,
        // then there is no buffering
        if (
            this.bufferingDetected
            && this.currentPlayPos > (this.lastPlayPos + offset)
            && !this.video.paused
        ) {
            if(this.callback !== null){
                this.callback('video', 'playing');
            }
            this.bufferingDetected = false
        }

        this.lastPlayPos = this.currentPlayPos;
    }

}

export { videoPlayerControls };
