class Helpers {

    constructor() {

    }

    debounce(callback, delay) {
        var timer = null;

        return function() {
            if (timer) return;

            callback.apply(this, arguments);
            timer = setTimeout(() => timer = null, delay);
        }
    }

    throttle(callback, delay) {

        let isThrottled = false, args, context;

        function wrapper() {
            if (isThrottled) {
                args = arguments;
                context = this;
                return;
            }

            isThrottled = true;
            callback.apply(this, arguments);

            setTimeout(() => {
                isThrottled = false;
                if (args) {
                    wrapper.apply(context, args);
                    args = context = null;
                }
            }, delay);
        }

        return wrapper;
    }

    // the value, value is in the actual range
    // a, b actual possible  range
    // c, d destination range / wanted range

    mapValue(x, a, b, c, d) {
        return c + (d - c) * ((x - a) / (b - a)) || 0;
    }

    getAngleB(a, b){
        let c = Math.sqrt((a*a + b*b - (2*a*b) * Math.cos(Math.radians(90))));
        let angleB = Math.acos((a*a + c*c - b*b) / (2*a*c));
        return Math.degrees(angleB);
    }

}

export { Helpers };
