
/********************************************************
*   DEPENDENCIES
*******************************/
import "media-match";

import polyfills from './polyfills.js';

import Picturefill from 'Picturefill';

import fitVid from './jquery.fitVid.js'
import { videoPlayerControls } from './videoPlayerControls.js';

import { VertexMath } from './vertexMath.js';
import { Helpers } from './helpers.js';

import TweenMax from "gsap/TweenMax";
import TimelineMax from "gsap/TimelineMax";
import ModifiersPlugin  from 'gsap/ModifiersPlugin';
import TextPlugin  from 'gsap/TextPlugin';
import AttrPlugin  from 'gsap/AttrPlugin';
import CustomEase from "./gsap-bonus/CustomEase";
import DrawSVGPlugin from './gsap-bonus/DrawSVGPlugin';

import ZingTouch from 'zingtouch';
import normalizeWheel from 'normalize-wheel';

import Swiper from 'Swiper';

import tippy from 'tippy.js'
import nearby from './nearby.js';

//import AudioPlayer from './audio-player.js';

/********************************************************
*   VARS
*******************************/
// Scene Data
const body = $('body');
const _document = $(document);
const perspectiveElements = $('section.space, section.video-player, section.overlay');
const video_player = $('section.video-player');
const progressBar = $('div.progress-bar');
const slides = $('div.swiper-container div.swiper-slide');
const video = $('video').get(0);

const fakemask = {
    top: video_player.find('div.top'),
    right: video_player.find('div.right'),
    bottom: video_player.find('div.bottom'),
    left: video_player.find('div.left')
};

let vertex;
let vertices_absolute = {a:0,b:0,c:0,d:0};
const pixela = video_player.find('div.vertex.a');
const pixelb = video_player.find('div.vertex.b');
const pixelc = video_player.find('div.vertex.c');
const pixeld = video_player.find('div.vertex.d');

// Progress Data
let initialScroll = false;
let scrollSuspended = false;
let scrollSuspendedFromLoading = false;
let timeline = null;
export let scenes = [];
export let currentScene = 0;
let lifebuttonanim;
let storiesbuttonanim;
let videocontrolsanim;
let overlayanimation;
let navanim;
let navigation;
let scrollTop = 0;
let sceneSwiper = null;

export const scrollData = {
    currentValue: 0,
    progress: 0,
    total: 1000 * 7,
    direction: "FORWARD",
    points: [], // at x progress
    minDistanceForApproximation: 0.0026666666666667 * 7
};

// Window Data
let windowSize = {
    width:window.innerWidth,
    height:window.innerHeight
}

// Mouse Data
let normalized;
const acceleration = 0.01;
let mouse = { x: 0, y: 0 };
let transformOrigin = {
    element: {x:0, y:0},
    x: 50,
    y: 50,
    perspectiveMax: 0 // max in range from center = 50, example 45 - 55
};
let appliedTransformOrigin = {};

// Player
let videoPlayer;
export let globalVideoState = {muted:true, volume:0, volumeInitialised:false, currentVideo:-1, uiTimerDelay:3, uiTimer:null, atPlayer:false, ignoreVideoEvent:false};

//export const audioPlayer = new AudioPlayer();
//const audioSettings = {volume:0};

// Overlay
export let overlay = {type:null, active:false, element:$('section.overlay'), activePath:null, activePathAnimation:null, paths:[
    {bullets:[725, 1094, 1194, 1300], years:29, name:"Samir"},
    {bullets:[782, 979, 1175, 1254], years:23, name:"Dania"},
    {bullets:[459, 882, 1230, 1351], years:26, name:"Saâd"},
    {bullets:[511, 715, 1023, 1072, 1329], years:28, name:"Selma"},
    {bullets:[635, 865, 911, 1049, 1187, 1279, 1325], years:33, name:"Hannan"},
    {bullets:[553, 712, 1172, 1279], years:30, name:"Bujar"},
    {bullets:[191, 643, 996, 1241], years:38, name:"Nuran"},
    {bullets:[183, 795, 1101, 1430], years:25, name:"Serpil"},
    {bullets:[637, 956, 1303, 1430], years:24, name:"Sohail"},
    {bullets:[632, 738, 790, 1001, 1107, 1265, 1370], years:29, name:"Atilla"},
]};

let mobileoverlay = {type:null}

// Misc
let desktopInitialised = false;
let mobileInitialised = false;
let mql = null;
const quality = 1;

const helper = new Helpers();

/********************************************************
*   MAIN FUNCTIONS
*******************************/
export function init(){

    let mask = null;
    $('div.scene:not(.scene-transition)').each(function(index, element){
        if(index === 0){
            scenes.push({
                element: $(element),
                timeline: null,
                vertex: null,
                video: null,
                mask: null
            });
        }else{
            if(quality === 1){
                mask = initMaskAnim($(element).find('div.mask-container'));
            }else{
                mask = null;
            }
            scenes.push({
                element: $(element),
                timeline: null,
                vertex: new VertexMath($(element).find('div.mask-container').get(0)),
                video: {state:'looping', currentTime:0, source:null},
                mask: mask
            });
        }
    });

    scenes[1].video.source = "samir";
    scenes[2].video.source = "dania";
    scenes[3].video.source = "saad";
    scenes[4].video.source = "selma";
    scenes[5].video.source = "hannan";
    scenes[6].video.source = "bujar";
    scenes[7].video.source = "nuran";
    scenes[8].video.source = "serpil";
    scenes[9].video.source = "sohail";
    scenes[10].video.source = "attila";

    // Video player
    videoPlayer = new videoPlayerControls(video, $('section.ui div.controls').get(0));

    // Register Video Events
    videoPlayer.registerCallback(function(type, state){

        //console.log(type, state);
        if(template.isDesktop){
            if(type === "playpause" && !globalVideoState.ignoreVideoEvent){
                scenes[globalVideoState.currentVideo+1].video.state = (state === "pause") ? "paused" :  "playing";
            }
        }
        if(type === "video"){
            if(state === "buffering"){
                TweenMax.to($('div.buffering'), 0.5, {autoAlpha:1, ease:Power2.easeInOut});
                scrollSuspendedFromLoading = true;
            }else{
                TweenMax.to($('div.buffering'), 0.5, {autoAlpha:0, ease:Power2.easeInOut});
                scrollSuspendedFromLoading = false;
            }
        }

    });

    window.requestAnimationFrame(render);

    mql = window.matchMedia('screen and (max-width: 1023px)');
    mql.addListener(function(mql){
        if (mql.matches) {
            initMobile();
        } else {
            initDesktop();
        }
    });

    if (mql.matches) {
        initMobile();
    } else {
        initDesktop();
    }

}

function initDesktop(){

    if(desktopInitialised) return;

    // Ipad wide
    document.addEventListener("touchmove", function(e){
        //console.log("should stop");
        e.preventDefault();
        e.stopPropagation();
    }, { passive: false });

    // Events
    $(document).on('click', 'div.button.stories', e => {
        showOverlay('stories');
    });
    $(document).on('click', 'div.button.life', e => {
        showOverlay('life');
    });
    $(document).on('click', 'div.close', e => {
        hideOverlay();
    });
    $(document).on('click', 'section.overlay div.swiper-slide.swiper-slide-active', e => {
        let number = $(e.currentTarget).attr("data-video-number");
        goToVideo(number);
    });

    $(document).on('touchstart click', 'section.space div.scene-intro button', e => {
        e.preventDefault();
        e.stopPropagation();
        getUserInteraction();
        scroll({type:"intro", pixelY:200}, true);
        $('section.ui').removeClass("disabled");
    });

    // audioPlayer.loadSound('assets/audio/intro.mp3',  () => {
    //     console.log("sound file loaded");
    //     audioPlayer.playSound(0, true);
    // });

    window.addEventListener('resize', helper.throttle((event) => {
        _resize(event);
    }, 200), {
        capture: true,
        passive: true
    });

    document.addEventListener('wheel', (event) => {
        normalized = normalizeWheel(event);
        normalized.type = "wheel";
        scroll(normalized, true);
    }, {
        capture: true,
        passive: true
    });

    window.addEventListener("mousemove", function(event){
        mouse.x = event.clientX;
        mouse.y = event.clientY;
        window.clearTimeout(globalVideoState.uiTimer);
        if(globalVideoState.atPlayer){
            //console.log('should show player ui');
            showVideoPlayerUi();
            globalVideoState.uiTimer = window.setTimeout(hideVideoPlayerUi, globalVideoState.uiTimerDelay * 1000);
        }
        getPathPosition();
    }, {
        capture: true,
        passive: true
    });

    new ZingTouch.Swipe({
        numInputs: 2,
        maxRestTime: 100,
        escapeVelocity: 0.25
    });

    const myRegion = new ZingTouch.Region(document.body, false, false);
    let touchdata = {};

    let hasSwiped = false;
    myRegion.bind($("main").get(0), 'swipe', helper.throttle((event) => {
        touchdata.type = "touch";
        touchdata.direction = event.detail.data[0].currentDirection;
        scroll(touchdata, true);
        hasSwiped = true;
    }, 200));

    // Swiper
    sceneSwiper = new Swiper('.swiper-container', {
        speed: 400,
        centeredSlides:true,
        slidesPerView: 3,
        slidesPerGroup:1,
        spaceBetween: 0,
        grabCursor:true,
        observer: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });

    // Video Cover
    $(video).fitVid({ratio:1920/1080});

    // Scenes
    scenes[0].timeline = scene_intro_timeline();
    scenes[1].timeline = sceneAnimation(1, true);
    scenes[2].timeline = sceneAnimation(2, true);
    scenes[3].timeline = sceneAnimation(3, true);
    scenes[4].timeline = sceneAnimation(4, true);
    scenes[5].timeline = sceneAnimation(5, true);
    scenes[6].timeline = sceneAnimation(6, true);
    scenes[7].timeline = sceneAnimation(7, true);
    scenes[8].timeline = sceneAnimation(8, true);
    scenes[9].timeline = sceneAnimation(9, true);
    scenes[10].timeline = sceneAnimation(10, false);

    //console.log(scenes);

    // Navigation
    navigation = new TimelineMax({onUpdateParams:["{self}"], onUpdate:mainAnimProgress});

    navigation.add("intro");
    navigation.add(scenes[0].timeline);
    navigation.add("introEnd");

    navigation.add("sceneStart1");
    navigation.add([sceneIn(true), scenes[1].timeline]);
    navigation.add("sceneEnd1");

    navigation.add("sceneStart2");
    navigation.add([sceneIn(), scenes[2].timeline]);
    navigation.add("sceneEnd2");

    navigation.add("sceneStart3");
    navigation.add([sceneIn(), scenes[3].timeline]);
    navigation.add("sceneEnd3");

    navigation.add("sceneStart4");
    navigation.add([sceneIn(), scenes[4].timeline]);
    navigation.add("sceneEnd4");

    navigation.add("sceneStart5");
    navigation.add([sceneIn(), scenes[5].timeline]);
    navigation.add("sceneEnd5");

    navigation.add("sceneStart6");
    navigation.add([sceneIn(), scenes[6].timeline]);
    navigation.add("sceneEnd6");

    navigation.add("sceneStart7");
    navigation.add([sceneIn(), scenes[7].timeline]);
    navigation.add("sceneEnd7");

    navigation.add("sceneStart8");
    navigation.add([sceneIn(), scenes[8].timeline]);
    navigation.add("sceneEnd8");

    navigation.add("sceneStart9");
    navigation.add([sceneIn(), scenes[9].timeline]);
    navigation.add("sceneEnd9");

    navigation.add("sceneStart10");
    navigation.add([sceneIn(), scenes[10].timeline]);
    navigation.add("sceneEnd10");


    navigation.pause();
    navigation.progress(0.0001);

    calculatePoints();

    // Animations
    initPerspectiveAnim();
    initControlsAnim();
    initLifeButtonAnim();
    initStoriesButtonAnim();
    initNavAnim();
    initOverlayAnim();

    // Init First Mask State
    setFakeMask(true, 1);

    // Init First Video State
    replaceVideo();

    // Subscribe to video end event
    video.addEventListener('ended', handleVideoEnd, false);

    desktopInitialised = true;

    // Proximity Hovers
    $('section.ui div.button').each(function(){

        let testanim = TweenMax.fromTo($(this).find("span"), 1, {y:10, autoAlpha:0}, {y:0, autoAlpha:1, paused:true, ease:Power2.easeInOut});
        new Nearby($(this).get(0), {
            onProgress: (distance) => {
                testanim.progress(Math.max(0, helper.mapValue(distance, 400, 0, 0, 1)));
            }
        });

    });

    desktopInitialised = true;

}

function initMobile(){

    //console.log('init mobile');

    if(mobileInitialised) return;

    $(document).on('click', 'navigation div.menu', e => {
        showMenu();
    });
    $(document).on('click', 'div.mobile-navigation div.close', e => {
        hideMenu();
    });

    $('div.swiper-wrapper').on('click', 'div.play', function(e){
        let number = $(e.currentTarget).parent().parent().attr("data-video-number");
        showVideoMobile(number);
    });
    $('div.swiper-wrapper').on('click', 'div.button.more', e => {
        let number = $(e.currentTarget).closest('.swiper-slide').attr("data-video-number");
        showLifeMobile(number);
    });

    $(document).on('click', 'header navigation div.close', e => {
        if(mobileoverlay.type === "life"){
            hideLifeMobile();
        }else{
            hideVideoMobile();
        }
    });

    mobileInitialised = true;

}


function getUserInteraction(){

    if(!globalVideoState.volumeInitialised && !mql.matches){
        video.muted = false;
        video.volume = 0;
        video.play();
        globalVideoState.volumeInitialised = true;
        globalVideoState.muted = false;
    }

}

function render(){

    window.requestAnimationFrame(render);

    setVideoControlTimeUpdate();

    if(desktopInitialised){
        handleVideoLoop();
    }

}

function showMenu(){
    //console.log('should show menu');

    scrollTop = _document.scrollTop();
    body.addClass("toggle-navigation");

    //$('div.mobile-navigation').show();
    //$('body').addClass("noscroll");
    //noscroll = true;
}

function hideMenu(){
    //console.log('should hide menu');
    body.removeClass("toggle-navigation");
    _document.scrollTop(scrollTop);
    //$('div.mobile-navigation').hide();
    //$('body').removeClass("noscroll");
    //noscroll = false;
}

function getPathPosition(){

    // if(false){
    //     let x = mouse.x - $('.grid-container').offset().left;
    //     x = Math.min( Math.max(0, x), $('.grid-container').width() );
    //     let percent = x / $('.grid-container').width();
    //     if(overlay.activePathAnimation !== null){
    //         overlay.activePathAnimation.progress(percent);
    //     }
    // }

}

function _resize(){

    windowSize = {
        width:window.innerWidth,
        height:window.innerHeight
    };

    scenes.forEach( function(scene, index){
        if(index > 0){
            createZoomTween(index);
        }
    });

}

function initPerspectiveAnim(){

    // Mouse Perspective & Fake Mask
    TweenMax.set(transformOrigin.element, {x: transformOrigin.x, y: transformOrigin.y});
    appliedTransformOrigin = transformOrigin.element;

    TweenMax.to(transformOrigin.element, 1, {
        x: 0,
        y: 0,
        repeat: -1,
        ease: Linear.easeNone,
        modifiers: {
            x: function() {
                transformOrigin.x = helper.mapValue(((mouse.x / windowSize.width) * 100), 0, 100, (50 - transformOrigin.perspectiveMax), (50 + transformOrigin.perspectiveMax));
                return appliedTransformOrigin.x + (transformOrigin.x - appliedTransformOrigin.x) * acceleration;
            },
            y: function() {
                transformOrigin.y = helper.mapValue(((mouse.y / windowSize.height) * 100), 0, 100, (50 - transformOrigin.perspectiveMax), (50 + transformOrigin.perspectiveMax));
                return appliedTransformOrigin.y + (transformOrigin.y - appliedTransformOrigin.y) * acceleration;
            }
        },
        onUpdate:function(){

            // Max Perspective Change on Mouse Change depends on distance of element on z axis
            if(currentScene > 0){
                transformOrigin.perspectiveMax = helper.mapValue(scenes[currentScene].element[0]._gsTransform.z, -1500, 0, 0, 20);
                if(scenes[currentScene].element[0]._gsTransform.z > 100){
                    transformOrigin.perspectiveMax = 0;
                }
            }

            if(quality === 1){
                TweenMax.set(perspectiveElements, {perspectiveOrigin: appliedTransformOrigin.x + "% " + appliedTransformOrigin.y + "%"});
            }

            // Mask
            setFakeMask();

        }
    });

}

function initControlsAnim(){
    videocontrolsanim = TweenMax.fromTo($('main div.controls'), 1, {y:30, autoAlpha:0}, {y:0, autoAlpha:1, paused:true, ease:Power2.easeInOut});
}

function initLifeButtonAnim(){
    lifebuttonanim = TweenMax.fromTo($('div.button.life'), 1, {x:30, autoAlpha:0}, {x:0, autoAlpha:1, paused:true, ease:Power2.easeInOut});
}

function initStoriesButtonAnim(){
    storiesbuttonanim = TweenMax.fromTo($('div.button.stories'), 1, {x:-30, autoAlpha:0}, {x:0, autoAlpha:1, paused:true, ease:Power2.easeInOut});
}

function initNavAnim(){
    navanim = TweenMax.fromTo($('header navigation'), 1, {y:-30, autoAlpha:0}, {y:0, autoAlpha:1,  ease:Power2.easeInOut});
    navanim.progress(1);
}

function initOverlayAnim(){

    overlayanimation = new TimelineMax({paused:true});
    overlayanimation.add("start");
    overlayanimation.fromTo(overlay.element, 1, {autoAlpha:0}, {autoAlpha:1, ease:Power2.easeInOut}, "start");
    overlayanimation.fromTo(overlay.element.find("div.content-wrapper"), 1, {z:-200}, {z:0, ease:Power2.easeInOut}, "start");

    //timeline.fromTo($('div.scene.scene-intro div.content-wrapper'), 0.8, {z:100, opacity:1}, {z:100, opacity:0, ease:Linear.easeNone});

}

function initOverlayLifeAnim(){

    //console.log(currentScene, overlay.activePath);

    let path = $('div.grid-container div.graph-'+currentScene+' path').get(0);
    let length = path.getTotalLength();

    TweenMax.set(path, {drawSVG:"0%"});
    TweenMax.set($('div.grid-container div.graph-'+currentScene+' circle.point'), {attr:{r:0}});
    TweenMax.set($('div.grid-container div.graph-'+currentScene+' circle.animate'), {attr:{r:0,"fill-opacity":0, "stroke-opacity":0}});
    let pathanimation = new TimelineMax({paused:true});

    TweenMax.fromTo($('div.grid-container div.graph-'+currentScene+' circle.animate'), 1.2,  {attr:{r:8}}, {attr:{r:16}, repeat:-1, yoyo:true, ease:Back.easeOut});

    overlay.paths[overlay.activePath].bullets.forEach(function(bullet, index){
        pathanimation.to(path, 1, {drawSVG: (bullet / length) * 100 + "%", ease:Linear.easeNone, onComplete:function(){
            $('div.grid-container div.graph-'+(overlay.activePath+1)+' svg g:eq(0)').get(0)._tippy.show();
            TweenMax.fromTo($('div.grid-container div.graph-'+currentScene+' svg g:eq('+index+') circle.animate'), 0.4, {attr:{"fill-opacity":0, "stroke-opacity":0}}, {attr:{"fill-opacity":0.4, "stroke-opacity":1}, ease:Back.easeOut});
            TweenMax.to($('div.grid-container div.graph-'+currentScene+' svg g:eq('+index+') circle.point'), 0.4, {attr:{r:8}, ease:Back.easeOut});
        }});
    });

    pathanimation.to(path, 1, {drawSVG:"100%", ease:Linear.easeNone});
    pathanimation.progress(0.0000001);

    return pathanimation;

}

function setOverlaySliderScene(){
    sceneSwiper.slideTo((currentScene-1), 0, false);
}

function initMaskAnim(element){

    // 4deg default
    let animation = new TimelineMax({paused:true, repeat:-1, yoyo:true});
    animation.add("start");
    animation.fromTo(element, 3, {rotationX:"-5deg", rotationY:"-5deg"}, {rotationX:"0deg", rotationY:"0deg", ease:Sine.easeIn});
    animation.add("middle");
    animation.fromTo(element, 3, {rotationX:"0deg", rotationY:"0deg"}, {rotationX:"5deg", rotationY:"5deg", ease:Sine.easeOut});
    animation.add("end");
    return animation;

}

function maskTransition(){

    //console.log('maskTransition', scrollData.direction);

    if(!globalVideoState.volumeInitialised){
        video.volume = globalVideoState.volume;
        video.muted = false;
        globalVideoState.muted = false;
        globalVideoState.volumeInitialised = true;
    }

    if(scrollData.direction === "FORWARD"){
        if(main.scenes[currentScene].mask !== null){
            main.scenes[currentScene].mask.timeScale(2);
            main.scenes[currentScene].mask.tweenTo("middle", {ease:Power2.easeInOut, onComplete:function(){
                main.scenes[currentScene].mask.timeScale(1);
            }});
        }
    }else{
        if(main.scenes[currentScene].mask !== null){
            main.scenes[currentScene].mask.resume();
        }
    }

}

function hideVideoPlayerUi(){

    //console.log("hide video player ui");

    if(overlay.active) return;
    //console.log('Hide video player ui');
    lifebuttonanim.reverse();
    videocontrolsanim.reverse();
    navanim.reverse();
    storiesbuttonanim.reverse();
}

function showVideoPlayerUi(){
    if(lifebuttonanim.progress() === 1) return;
    //console.log('Show video player ui');
    lifebuttonanim.play();
    videocontrolsanim.play();
    navanim.play();
    storiesbuttonanim.play();
}

function showWebsiteUi(){
    navanim.play();
    storiesbuttonanim.play();
}

function hideWebsiteUi(){
    //console.log('hide website ui');
    navanim.reverse();
    storiesbuttonanim.reverse();
}

function handleVideoPlayerButtons(direction, overwrite){

    //console.log("Should handle Video Player", direction, scrollData.direction);

    if(direction === "in"){

        if( (scrollData.direction === "FORWARD" && overwrite === undefined) || overwrite === "FORWARD"){
            lifebuttonanim.play();
            videocontrolsanim.play();
            changeVideoLoopState();
        }else{
            lifebuttonanim.reverse();
            videocontrolsanim.reverse();
        }

    }else{
        if( (scrollData.direction === "FORWARD" && overwrite === undefined) || overwrite === "FORWARD"){
            lifebuttonanim.reverse();
            videocontrolsanim.reverse();
        }else{
            lifebuttonanim.play();
            videocontrolsanim.play();
            changeVideoLoopState();
        }
    }

}

function handleStoriesButton(){
    if(scrollData.direction === "FORWARD"){
        storiesbuttonanim.play();
    }else{
        storiesbuttonanim.reverse();
    }
}

function handleSceneStart(sceneNumber){

    //console.log("Scene Start", sceneNumber, initialised);
    if(!desktopInitialised && sceneNumber !== 0) return;

    if(currentScene !== sceneNumber){

        //console.log('Scene Start For Real');

        currentScene = sceneNumber;

        scenes.forEach(function(scene){
            TweenMax.set(scene.element, {autoAlpha:0});
            //TweenMax.to(scene.element, 0.5, {autoAlpha:0});
            if(scene.mask !== null){
                scene.mask.pause();
            }

            //    if(scene.vertex != null){
            //    scene.vertex.resize();
            //}

        });

        TweenMax.set(scenes[currentScene].element, {autoAlpha:1});
        //TweenMax.to(scenes[currentScene].element, 0.5, {autoAlpha:1});

        if(scenes[currentScene].mask !== null){
            scenes[currentScene].mask.resume();
        }

        setOverlaySliderScene();

        console.log(`Scene Start Ready ${sceneNumber}`);

    }

}

let svgEffect = {value:17};

function sceneAnimation(sceneNumber, transition){

    let timeline = new TimelineMax({onUpdate:function(){
        // Mask
        handleSceneStart(sceneNumber);
        setFakeMask();
    }});

    // Camera Move
    timeline.add("cameramove");

    // // // Filter
    // timeline.fromTo(svgEffect, 9, {value:17}, {value:0, delay:1, onUpdate: () => {
    //     console.log("updating filter", svgEffect.value);
    //     document.getElementById("svgfilter").querySelector('feDisplacementMap').setAttribute("scale", svgEffect.value);
    //     document.getElementById("svgfilter").setAttribute("width","0");
    // }}, "cameramove");

    // Volume
    timeline.fromTo(globalVideoState, 8, {volume:0}, {volume:1, delay:2, onUpdate: () => {
        //audioPlayer.setVolume(audioSettings.volume);
        video.volume = globalVideoState.volume;
    }}, "cameramove");

    // Button
    if(sceneNumber === 1){
        timeline.call(handleStoriesButton, [], this, 0.01);
    }

    //timeline.fromTo(scenes[1].element.find('div.dimm'), 3, {opacity:1}, {opacity:0, ease:Linear.easeNone}, "cameramove");

    let startposition = {rotationX:"0deg",rotationY:"0deg"}
    let values = [];

    if(sceneNumber === 1){
        Object.assign(startposition, {x:-1500, y:0, z:-3000});
        values = [
            {x:-200, y:0, z:-1330},
            {x:600, y:0, z:-931},
            {x:100, y:0, z:-266},
            {x:0, y:0, z:100},
        ];
    }
    if(sceneNumber === 2){
        Object.assign(startposition, {x:100, y:-500, z:-3000});
        values = [
            {x:-100, y:500, z:-2500},
            {x:100, y:-500, z:-2000},
            {x:-100, y:0, z:-1000},
            {x:0, y:0, z:100}
        ];
    }
    if(sceneNumber === 3){
        Object.assign(startposition, {x:-500, y:800, z:-3000});
        values = [
            {x:0, y:200, z:-1330},
            {x:-500, y:0, z:-931},
            {x:0, y:-200, z:-266},
            {x:0, y:0, z:100},
        ];
    }
    if(sceneNumber === 4){
        Object.assign(startposition, {x:1500, y:-500, z:-3000});
        values = [
            {x:200, y:500, z:-1330},
            {x:600, y:-500, z:-931},
            {x:-100, y:0, z:-266},
            {x:0, y:0, z:100},
        ];
    }
    if(sceneNumber === 5){
        Object.assign(startposition, {x:-1500, y:0, z:-3000});
        values = [
            {x:-200, y:0, z:-1330},
            {x:600, y:0, z:-931},
            {x:100, y:0, z:-266},
            {x:0, y:0, z:100},
        ];
    }
    if(sceneNumber === 6){
        Object.assign(startposition, {x:100, y:-500, z:-3000});
        values = [
            {x:-100, y:500, z:-2500},
            {x:100, y:-500, z:-2000},
            {x:-100, y:0, z:-1000},
            {x:0, y:0, z:100}
        ];
    }
    if(sceneNumber === 7){
        Object.assign(startposition, {x:-500, y:800, z:-3000});
        values = [
            {x:0, y:200, z:-1330},
            {x:-500, y:0, z:-931},
            {x:0, y:-200, z:-266},
            {x:0, y:0, z:100},
        ];
    }
    if(sceneNumber === 8){
        Object.assign(startposition, {x:1500, y:-500, z:-3000});
        values = [
            {x:200, y:500, z:-1330},
            {x:600, y:-500, z:-931},
            {x:-100, y:0, z:-266},
            {x:0, y:0, z:100},
        ];
    }
    if(sceneNumber === 9){
        Object.assign(startposition, {x:-1500, y:0, z:-3000});
        values = [
            {x:-200, y:0, z:-1330},
            {x:600, y:0, z:-931},
            {x:100, y:0, z:-266},
            {x:0, y:0, z:100},
        ];
    }
    if(sceneNumber === 10){
        Object.assign(startposition, {x:100, y:-500, z:-3000});
        values = [
            {x:-100, y:500, z:-2500},
            {x:100, y:-500, z:-2000},
            {x:-100, y:0, z:-1000},
            {x:0, y:0, z:100}
        ];
    }

    timeline.fromTo(
        scenes[sceneNumber].element, // element
        10, // time
        startposition, // from
        { // to
            bezier:{type:"cubic", curviness:4, values:values},
            rotationX: "0deg",
            rotationY: "0deg",
            force3D:true,
            immediateRender:true,
            ease:Linear.easeNone,
            modifiers: {
                rotationX: function(value, target) {
                    return lookAtCenterX(target._gsTransform);
                },
                rotationY: function(value, target) {
                    return lookAtCenterY(target._gsTransform);
                }
            }
        },
        "cameramove" // label or offset
    );

    // Text Mask
    //timeline.fromTo(scenes[1].element.find('svg mask .tm1'), 5, {x:0}, {x:450, ease:Linear.easeNone}, "cameramove");
    //timeline.fromTo(scenes[1].element.find('svg mask .tm2'), 5, {x:0}, {x:450, delay:5, ease:Linear.easeNone}, "cameramove");

    // Show Text
    //timeline.fromTo($('#mygradient_1 stop:eq(1)'), 3, {attr:{"offset":"0%"}}, {attr:{"offset": "100%"}}, "cameramove");
    //timeline.fromTo($('#mygradient_1 stop:eq(0)'), 3, {attr:{"offset":"0%"}}, {attr:{"offset": "100%"}, delay:1}, "cameramove");

    //timeline.fromTo($('#mygradient_2 stop:eq(1)'), 3, {attr:{"offset":"0%"}}, {attr:{"offset": "100%"}, delay:4}, "cameramove");
    //timeline.fromTo($('#mygradient_2 stop:eq(0)'), 3, {attr:{"offset":"0%"}}, {attr:{"offset": "100%"}, delay:5}, "cameramove");

    // Background scale
    //timeline.fromTo(video, 8, {z:-1500}, {z:0, ease:Linear.easeNone}, "cameramove");

    // Mask Fade
    timeline.add("video-transition-in");

    let zoomTween = createZoomTween(sceneNumber);
    timeline.add(zoomTween, "video-transition-in");

    // Button
    timeline.call(handleVideoPlayerButtons, ["in"], null, "video-transition-in+=9");

    if(transition){
        timeline.add("video-transition-out");
        timeline.add(sceneOut());
    }

    timeline.progress(0.0000001);

    return timeline;

}


function createZoomTween(sceneNumber){

    let centerposition = {x:0,y:0};

    if(sceneNumber === 1){
        centerposition.x = 0;
        centerposition.y = 0;
    }
    if(sceneNumber === 2){
        centerposition.x = -50;
        centerposition.y = 0;
    }
    if(sceneNumber === 3){
        centerposition.x = 0;
        centerposition.y = 0;
    }
    if(sceneNumber === 4){
        centerposition.x = -50;
        centerposition.y = 0;
    }
    if(sceneNumber === 5){
        centerposition.x = 0;
        centerposition.y = 0;
    }
    if(sceneNumber === 6){
        centerposition.x = -50;
        centerposition.y = 0;
    }
    if(sceneNumber === 7){
        centerposition.x = 0;
        centerposition.y = 0;
    }
    if(sceneNumber === 8){
        centerposition.x = 0;
        centerposition.y = 0;
    }
    if(sceneNumber === 9){
        centerposition.x = 0;
        centerposition.y = 0;
    }
    if(sceneNumber === 10){
        centerposition.x = 0;
        centerposition.y = 0;
    }

    if(scenes[sceneNumber].thisisthetween !== undefined){
        scenes[sceneNumber].thisisthetween.kill();
    }

    let mytween = TweenMax.fromTo(scenes[sceneNumber].element, 10, {z:100}, {z:helper.mapValue(window.innerWidth, 1024, 2560, 1100, 1500), x:centerposition.x, y:centerposition.y, ease:Linear.easeNone, onStart:maskTransition, onReverseComplete:maskTransition, immediateRender:false});
    scenes[sceneNumber].thisisthetween = mytween;

    if(scenes[sceneNumber].timeline !== null){
        scenes[sceneNumber].timeline.add(mytween, "video-transition-in");
    }else{
        return mytween;
    }

}

function sceneIn(withoutTransition){

    let timeline = new TimelineMax();

    // Video Outtransition In
    timeline.add("video-transition-in");
    timeline.fromTo(video_player.find('div.dimm'), 1, {opacity:1}, {opacity:0, ease:Linear.easeNone}, "video-transition-in");

    // Transition Layer
    if(withoutTransition === undefined || withoutTransition === false){
        timeline.fromTo($('section.space div.scene-transition'), 1, {autoAlpha:1, z:0}, {autoAlpha:0, z:-100, ease:Linear.easeNone, immediateRender:false}, "cameramove");
    }

    return timeline;

}

function sceneOut(){

    let timeline = new TimelineMax();

    // Video Outtransition Out
    timeline.add("video-transition-out");

    timeline.fromTo(video_player.find('div.dimm'), 1, {opacity:0}, {opacity:1, ease:Linear.easeNone}, "video-transition-out");
    timeline.to(globalVideoState, 1.5, {volume:0, onUpdate:function(){
        video.volume = globalVideoState.volume;
    }}, "video-transition-out");

    // Transition Layer
    timeline.fromTo($('section.space div.scene-transition'), 1, {autoAlpha:0, z:-100}, {autoAlpha:1, z:0, ease:Linear.easeNone,}, "video-transition-out");

    // Button
    timeline.call(handleVideoPlayerButtons, ["out"], null, "video-transition-out+=0.4");
    timeline.call(replaceVideo, ["out"], null, "video-transition-out+=1.4");

    return timeline;

}

function scene_intro_timeline(){

    let timeline = new TimelineMax({onStart:function(){

    },onUpdate:function(){
        handleSceneStart(0);
    }});

    timeline.fromTo($('div.scene.scene-intro div.content-wrapper'), 0.8, {z:0, opacity:1}, {z:100, opacity:0, ease:Linear.easeNone});

    return timeline;

}

function calculatePoints(){

    const navigation_totaltime = navigation.duration();
    const navigation_labels = navigation.getLabelsArray();

    //console.log(navigation_labels, navigation_totaltime);

    scrollData.points.push({name:"start", time:0});

    let timeprogression = 0;
    let scene_index = 0;

    scenes.forEach(function(scene){

        // Nested Labels
        let totaltime = scene.timeline.duration();
        let labels = scene.timeline.getLabelsArray();
        let label_index = 0;

        labels.forEach(function(label){
            if(label_index != 0){
                scrollData.points.push({name:label.name, time: (timeprogression + label.time) / navigation_totaltime});
            }
            label_index++;
        });

        scrollData.points.push({name:navigation_labels[scene_index+1].name, time:navigation_labels[scene_index+1].time / navigation_totaltime});
        timeprogression = navigation_labels[scene_index+1].time;

        scene_index = scene_index + 2;

    });

    scrollData.points.forEach(function(point){
        if(point.name === "introEnd" || point.name === "sceneStart1"){
            point.time += 0.0008;
        }
    });

    //console.log(scrollData.points);

}

function mainAnimProgress(timeline){
    //console.log(timeline.progress());
}

function lookAtCenterY(position){

    //console.log(Math.min(-600, position.z/2) );
    let angleY = helper.getAngleB(Math.abs(position.x), Math.min(-600, position.z/2)); // better fixed value than position.z

    angleY += -90;

    if(position.x < 0){
        angleY *= -1;
    }

    //console.log(angleY);

    angleY = isNaN(angleY) ? 0 : angleY;
    return angleY;

}

function lookAtCenterX(position){

    //console.log(position);
    let angleX = helper.getAngleB(Math.abs(position.y), Math.min(-600, position.z/2)); // better fixed value than position.z

    angleX += -90;

    if(position.y > 0){
        angleX *= -1;
    }

    angleX = isNaN(angleX) ? 0 : angleX;

    return angleX;

}

function setFakeMask(initial, scene){

    if(currentScene === 0 && !initial) return;
    if(scene !== undefined){
        currentScene = scene;
    }

    //console.log(globalVideoState.atPlayer);

    scenes[currentScene].vertex.update();
    let vertex = scenes[currentScene].vertex.v;

    TweenMax.set(pixela, {x:vertex.a.x, y:vertex.a.y, z:Math.min(600, vertex.a.z)});
    TweenMax.set(pixelb, {x:vertex.b.x, y:vertex.b.y, z:Math.min(600, vertex.b.z)});
    TweenMax.set(pixelc, {x:vertex.c.x, y:vertex.c.y, z:Math.min(600, vertex.c.z)});
    TweenMax.set(pixeld, {x:vertex.d.x, y:vertex.d.y, z:Math.min(600, vertex.d.z)});

    vertices_absolute.a = pixela.get(0).getBoundingClientRect();
    vertices_absolute.b = pixelb.get(0).getBoundingClientRect();
    vertices_absolute.c = pixelc.get(0).getBoundingClientRect();
    vertices_absolute.d = pixeld.get(0).getBoundingClientRect();

    //console.log(windowSize.width);

    TweenMax.set(fakemask.top, {y:Math.max(0, Math.max(vertices_absolute.a.top, vertices_absolute.b.top) + 30)});
    TweenMax.set(fakemask.right, {x:Math.min(windowSize.width, Math.min(vertices_absolute.b.left, vertices_absolute.c.left) - 30)});
    TweenMax.set(fakemask.bottom, {y:Math.min(windowSize.height, Math.min(vertices_absolute.c.top, vertices_absolute.d.top) - 30)});
    TweenMax.set(fakemask.left, {x:Math.max(0, Math.max(vertices_absolute.a.left, vertices_absolute.d.left) + 30)});

}

function scroll(data, animate, disableSnap){

    disableSnap = disableSnap || false;

    // Do not allow Scroll without interaction request
    if(!globalVideoState.volumeInitialised) return;

    // Do not allow scroll if its suspended
    if(scrollSuspended) return;
    if(scrollSuspendedFromLoading) return;

    // Add or Subtract Scroll Value
    if(data.type === "wheel"){
        scrollData.currentValue += data.pixelY;
        scrollData.currentValue = scrollData.currentValue.clamp(0, scrollData.total);
        scrollData.direction = (data.pixelY > 0) ? "FORWARD" : "BACKWARD";
    }else if(data.type === "touch"){
        scrollData.direction = (data.direction < 180) ? "FORWARD" : "BACKWARD";
        if(scrollData.direction === "FORWARD"){
            scrollData.currentValue += (scrollData.total / scenes.length-1) / 3;
        }else{
            scrollData.currentValue -= (scrollData.total / scenes.length-1) / 3;
        }
        scrollData.currentValue = scrollData.currentValue.clamp(0, scrollData.total);
    }else if(data.type === "manual" || data.type === "intro"){
        scrollData.direction = (data.pixelY > scrollData.currentValue) ? "FORWARD" : "BACKWARD";
        scrollData.currentValue = data.pixelY;
        scrollData.currentValue = scrollData.currentValue.clamp(0, scrollData.total);
    }

    // No need to continue if we are at the start or end
    if( (scrollData.progress === 0 && scrollData.direction === "BACKWARD") || (scrollData.progress === scrollData.points[scrollData.points.length-1].time && scrollData.direction === "FORWARD" ) ){
        return;
    }

    let snapPoint = false;
    if(animate && !disableSnap){
        // Snap to Point Value for pacing and clean segment completion
        snapPoint = getSnapForCurrentProgress();
    }
    if(snapPoint !== false && snapPoint.name !== "introEnd"){

        //console.log('snap scroll from:', scrollData.progress);
        //console.log('snap scroll to:', snapPoint);

        // Rewind to exactly segment and recalculate currentValue from progress value
        scrollData.progress = snapPoint.time;
        scrollData.currentValue = scrollData.total * snapPoint.time;

        // Create Artificial Slow Down / Pause only if not at start or end
        if(scrollData.progress > 0 && scrollData.progress < scrollData.points[scrollData.points.length-1].time && (snapPoint.name != "introEnd" && snapPoint.name != "sceneStart1") && (snapPoint.name != "video-transition-in") ){
            //suspendScrollForSeconds(1.25);
            scrollSuspended = true;
        }

        if(snapPoint.name !== "video-transition-out" ){
            window.clearTimeout(globalVideoState.uiTimer);
            if(globalVideoState.atPlayer){
                globalVideoState.atPlayer = false;
                showWebsiteUi();
            }
        }

    }else{

        scrollData.progress = getProgression();

        window.clearTimeout(globalVideoState.uiTimer);
        if(globalVideoState.atPlayer){
            globalVideoState.atPlayer = false;
            showWebsiteUi();
        }

    }

    if(animate){

        // Animate to Progress
        let ease = CustomEase.create("custom", "M0,0 C0,0.408 0.347,0.748 0.4,0.8 0.451,0.851 0.598,1 1,1");
        let duration = 1.2;
        if(data.type === "manual"){
            //ease = Power2.easeInOut;
            ease = CustomEase.create("custom", "M0,0 C0.3,0 0.6,0.2 0.7,0.3 0.797,0.397 1,0.7 1,1");
            duration = 1;
        }
        if(data.type === "intro"){
            //ease = Power2.easeInOut;
            ease = Power2.easeInOut;
            duration = 1.8;
        }
        if(data.type === "touch"){
            //ease = Power2.easeInOut;
            //ease = CustomEase.create("custom", "M0,0 C0,0.408 0.347,0.748 0.4,0.8 0.451,0.851 0.598,1 1,1");
            //duration = 0.5;
        }

        TweenMax.to(navigation, duration, {progress:scrollData.progress, ease:ease, onComplete:function(){

            if(scrollSuspended){
                //suspendScrollForSeconds(0.5);
                scrollSuspended = false;
            }

            // Check if we are at the Video Player
            if(snapPoint !== false){
                if(snapPoint.name === "video-transition-out" || snapPoint.name === ("sceneEnd" + (scenes.length - 1))  ){
                    //console.log('we are at the video player');
                    // Listen for mouse activity
                    globalVideoState.atPlayer = true;
                    window.clearTimeout(globalVideoState.uiTimer);
                    globalVideoState.uiTimer = window.setTimeout(hideVideoPlayerUi, globalVideoState.uiTimerDelay * 1000);
                }
            }

        }});

    }else{
        // Set Progress (hack to set rotation)
        TweenMax.set(navigation, {progress:scrollData.progress-0.002});
        TweenMax.to(navigation, 0.1, {progress:scrollData.progress, ease:CustomEase.create("custom", "M0,0 C0,0.408 0.347,0.748 0.4,0.8 0.451,0.851 0.598,1 1,1")});
    }

    //console.log(scrollData);

}

function getProgression(){
    return scrollData.currentValue / scrollData.total;
}

function suspendScrollForSeconds(seconds){
    scrollSuspended = true;
    window.setTimeout(function(){ scrollSuspended = false; }, seconds * 1000);
}

function getSnapForCurrentProgress(){

    let snapTo = false;
    let i = 0;
    let length = scrollData.points.length;
    let point;

    if(scrollData.direction === "FORWARD"){

        while(i < length){
            if(scrollData.points[i].time > scrollData.progress || i === length-1 ){
                point = scrollData.points[i];
                break;
            }
            i++;
        }

        if(getProgression() >= point.time){
            snapTo = point;
            //console.log('Will Snap because it is over or at the next segment', getProgression(), point.time);
        }else if(Math.abs(point.time - getProgression()) < scrollData.minDistanceForApproximation){
            snapTo = point;
            //console.log('Will Snap because it is near the next segment', Math.abs(point.time - getProgression()));
        }

    }else{

        while(length--){
            if(scrollData.points[length].time < scrollData.progress){
                point = scrollData.points[length];
                break;
            }
        }

        if(getProgression() <= point.time){
            snapTo = point;
            //console.log('Will Snap because it is over or at the next segment', getProgression(), point.time);
        }else if(Math.abs(point.time - getProgression()) < scrollData.minDistanceForApproximation){
            snapTo = point;
            //console.log('Will Snap because it is near the next segment', Math.abs(point.time - getProgression()));
        }

    }

    return snapTo;

}

/****************************************************************************************************************
****************************************************************************************************************
*   OVERLAYS
***************************************************************************************
***************************************************************************************/

function showOverlay(type){

    scrollSuspended = true;
    overlay.type = type;
    overlay.active = true;
    globalVideoState.ignoreVideoEvent = true;
    video.pause();
    globalVideoState.ignoreVideoEvent = false;
    overlay.element.find("div.overlay-content").hide().filter(".overlay-"+type).show();

    overlayanimation.play();
    overlay.element.addClass("enable-interaction");
    if(type === "life"){

        overlay.element.addClass('more-transparence');

        overlay.element.find('div.graph').hide().filter(".graph-"+currentScene).show();
        overlay.element.find(".close").addClass("close-right");

        //console.log(overlay.activePath, overlay, currentScene);

        if(overlay.activePath !== (currentScene-1) ){

            overlay.activePath = (currentScene-1);

            $('div.grid-container div.graph-'+(overlay.activePath+1)+' svg g').each(function(index, item){

                let element = $('div.grid-container div.graph-'+(overlay.activePath+1)+' div.tooltip-template-'+ (index+1)).get(0); //.cloneNode(true);
                //console.log(element);

                let offset = index === 3 ? -100 : 140;

                tippy(item, {
                    theme: 'sms',
                    html: element,
                    arrow: true,
                    arrowTransform: 'rotate(-90deg)',
                    flip: true,
                    flipBehavior: 'flip',
                    trigger: "mouseenter",
                    followCursor: false,
                    interactive: false,
                    multiple: false,
                    offset: offset +', 0',
                    distance: 35,
                    inertia: false,
                    hideOnClick: false,
                    livePlacement:true,
                    createPopperInstanceOnInit: true,
                    onShow:function(instance){

                        let tooltips = Array.from(document.querySelectorAll('[data-tippy]'), el => el._tippy);

                        tooltips.forEach(function(tooltip){
                            if(tooltip.id != instance.id && tooltip.popperInstance !== null){
                                tooltip.hide();
                            }
                        });

                    }
                });

            });

            overlay.activePathAnimation = initOverlayLifeAnim();
            overlay.element.find(".label-years-end span").text(overlay.paths[overlay.activePath].years);
            overlay.element.find("h2 span").text(overlay.paths[overlay.activePath].name);

        }

        TweenMax.to(overlay.activePathAnimation, 3, {progress:1, ease:Power2.easeInOut});


    }else{
        overlay.element.find(".close").removeClass("close-right");
    }
    TweenMax.fromTo($("section.ui"), 1, {autoAlpha:1}, {autoAlpha:0, ease:Power2.easeInOut});
}

function hideOverlay(){

    if(scenes[globalVideoState.currentVideo+1].video.state !== "paused"){
        video.play();
    }
    let tooltips = Array.from(document.querySelectorAll('[data-tippy]'), el => el._tippy);
    tooltips.forEach(function(tooltip){
        tooltip.hide();
    });
    overlayanimation.reverse();
    scrollSuspended = false;
    overlay.type = null;
    overlay.active = false;
    overlay.element.removeClass("enable-interaction more-transparence");
    TweenMax.fromTo($("section.ui"), 1, {autoAlpha:0}, {autoAlpha:1, ease:Power2.easeInOut});

}


/****************************************************************************************************************
****************************************************************************************************************
*   VIDEO
***************************************************************************************
***************************************************************************************/
function goToVideo(number){

    scrollSuspended = false;
    number = parseInt(number);

    let from, to;
    from = (number * 3) - 1;
    to = number * 3;

    //console.log("animating to video", number, scrollData, scrollData.points[from], scrollData.points[to]);

    overlayanimation.reverse();
    overlay.type = null;
    overlay.active = false;
    TweenMax.fromTo(video_player.find('div.dimm'), 1, {opacity:0}, {opacity:1, ease:Power2.easeInOut, onComplete:function(){

        scroll({type:"manual", pixelY:scrollData.points[from].time * scrollData.total}, false);
        video.play();
        TweenMax.fromTo(video_player.find('div.dimm'), 0.7, {opacity:1}, {opacity:0, ease:Power2.easeInOut});

        window.setTimeout(function(){
            //console.log(scrollData.points[to].time * scrollData.total);
            scroll({type:"manual", pixelY:scrollData.points[to].time * scrollData.total}, true);
        }, 1000);

    }});

    overlay.element.removeClass("enable-interaction");
    TweenMax.fromTo($("section.ui"), 1, {autoAlpha:0}, {autoAlpha:1, ease:Power2.easeInOut});
    videocontrolsanim.reverse();

}

function setVideoControlTimeUpdate(){
    //console.log("setting progress bar");
    TweenMax.set(progressBar, {width:(video.currentTime / video.duration) * 100 + "%"});
}

function handleVideoLoop(){

    if(scenes[globalVideoState.currentVideo+1] === undefined) return;

    if(scenes[globalVideoState.currentVideo+1].video.state === "looping"){
        if(video.currentTime >= 7.463){
            video.currentTime = 0;
        }
    }

    if(scenes[globalVideoState.currentVideo+1].video.state !== "paused" && scenes[globalVideoState.currentVideo+1].video.state !== "ended"){
        scenes[globalVideoState.currentVideo+1].video.currentTime = video.currentTime;
    }

}

function changeVideoLoopState(){
    if(scenes[globalVideoState.currentVideo+1] === undefined) return;
    scenes[globalVideoState.currentVideo+1].video.state = "playing";
}

function handleVideoEnd(e){

    // reset currenttime of video so its ready for next time
    scenes[globalVideoState.currentVideo+1].video.state = "ended";
    scenes[globalVideoState.currentVideo+1].video.currentTime = 0;

    if( currentScene === (scenes.length-1) ){
        showOverlay('stories');
    }else{
        scroll({type:"intro", pixelY: (scrollData.currentValue + 288) }, true, true);
    }

}

function replaceVideo(){

    if(scrollData.direction === "FORWARD"){
        globalVideoState.currentVideo++;
    }else{
        globalVideoState.currentVideo--;
    }

    if(globalVideoState.currentVideo < 0 || globalVideoState.currentVideo > scenes.length-2){
        return;
    }

    let filetype;
    video.setAttribute('poster', template.siteUrl + 'assets/images/poster-' + (globalVideoState.currentVideo+1) + '.jpg');

    let sources = video.querySelectorAll('source');
    for (var i = 0; i < sources.length; i++) {
        filetype = (i === 0) ? ".webm" : ".mp4";
        sources[i].setAttribute("src", "assets/videos/" + scenes[globalVideoState.currentVideo+1].video.source + filetype);
    }
    video.preload = "metadata";
    video.load();

    if(scenes[globalVideoState.currentVideo+1].video.state === "looping"){
        video.play();
    }

    if(scenes[globalVideoState.currentVideo+1].video.state === "paused"){
        video.currentTime = scenes[globalVideoState.currentVideo+1].video.currentTime;
    }

    if(scenes[globalVideoState.currentVideo+1].video.state === "playing" || scenes[globalVideoState.currentVideo+1].video.state === "ended"){
        video.currentTime = scenes[globalVideoState.currentVideo+1].video.currentTime;
        video.play();
    }

}

function showVideoMobile(number){

    //console.log('show video mobile');

    mobileoverlay.type = "video";

    video.volume = 0.5;
    video.muted = false;
    //video.setAttribute("controls", "controls");
    //video.setAttribute("preload", "metadata");

    //video.poster = "images/episode"+number+".jpg";

    let filetype;
    $(video).find('source').each(function(index, element){
        filetype = (index === 0) ? ".webm" : ".mp4";
        element.src = "assets/videos/" + scenes[number].video.source + filetype;
    });

    //video.src = "videos/" + scenes[number].video.source + ".webm";

    video.load();
    video.play();

    scrollTop = _document.scrollTop();
    body.addClass("toggle-videoplayer");

    //$('header div.menu').hide();
    //$('header div.close').show();
    //overlay.element.hide();
    //video_player.show();

}

function hideVideoMobile(){

    mobileoverlay.type = null;
    video.pause();

    body.removeClass("toggle-videoplayer");
    _document.scrollTop(scrollTop);

    //$('header div.menu').show();
    //$('header div.close').hide();
    //overlay.element.show();
    //video_player.hide();

}

function showLifeMobile(number){

    //console.log(number);

    mobileoverlay.type = "life";
    overlay.element.find("h2 span").text(overlay.paths[(number-1)].name);

    //$('header div.menu').hide();
    //$('header div.close').show();

    //overlay.element.find("div.overlay-stories").hide();
    //overlay.element.find("div.overlay-life").show();
    //overlay.element.addClass("overlay-mobile-active");
    overlay.element.find("div.overlay-life div.graph-"+number).show();

    scrollTop = _document.scrollTop();
    _document.scrollTop(0);
    body.addClass("toggle-life");

}

function hideLifeMobile(){

    mobileoverlay.type = null;

    //$('header div.menu').show();
    //$('header div.close').hide();

    //overlay.element.removeClass("overlay-mobile-active");
    overlay.element.find("div.overlay-life div.graph").hide();

    body.removeClass("toggle-life");
    _document.scrollTop(scrollTop);

}

/****************************************************************************************************************
****************************************************************************************************************
*   HELPERS
***************************************************************************************
***************************************************************************************/

Math.degrees = function(radians) {
    return radians * (180 / Math.PI);
};

Math.radians = function(degrees) {
    return degrees * Math.PI / 180;
};

Number.prototype.clamp = function(min, max) {
    return Math.min(Math.max(this, min), max);
};
